<template>
  <div class="wrap-edit">
    <el-card>
      <FloatTitBtn>
        <div class="edit-header">
          <span>建设用地许可证申请表规划局意见</span>
          <el-button v-if="!readOnly" type="primary" @click="onSubmit"
            >保存</el-button
          >
        </div>
      </FloatTitBtn>
      <div class="edit-container">
        <el-form
          :disabled="readOnly"
          ref="baseform"
          label-position="left"
          :model="form"
          label-width="120px"
        >
          <el-row :gutter="24">
            <el-col :span="24">
              <el-form-item label="许可证号:">
                <el-input v-model="form.XKZH"></el-input>
              </el-form-item>
            </el-col>
          </el-row>
          <el-row :gutter="24">
            <el-col :span="24">
              <el-form-item label="核准用地面积:">
                <el-input v-model="form.HZYDMJ"></el-input>
              </el-form-item>
            </el-col>
            <el-col :span="24">
              <el-form-item label="规划用地性质:">
                <el-input v-model="form.GHYDXZ"></el-input>
              </el-form-item>
            </el-col>
            <el-col :span="24">
              <el-form-item label="意见:">
                <el-input
                  v-model="form.YJ"
                  type="textarea"
                  :rows="5"
                ></el-input>
              </el-form-item>
            </el-col>
            <el-col :span="12">
              <el-form-item label="经办人:">
                <el-input v-model="form.JBRXM" disabled></el-input>
              </el-form-item>
            </el-col>
            <el-col :span="12">
              <el-form-item label="日期:">
                <el-date-picker
                  disabled
                  style="width: 100%"
                  v-model="form.JBRQ"
                  placeholder="经办日期"
                >
                </el-date-picker>
              </el-form-item>
            </el-col>
          </el-row>
        </el-form>
      </div>
    </el-card>
  </div>
</template>

<script>
import { ElMessage } from "element-plus";
import mgr from "@/services/security.js";
import FloatTitBtn from '../../components/FloatTitBtn.vue';
export default {
  name:'jsydghxkyj',
  components: { FloatTitBtn },
  data() {
    return {
      readOnly: true,
      form: {
        Id: "",
        BJID: "",
        XKZH: "",
        HZYDMJ: "",
        GHYDXZ: "",
        YJ: "",
        JBRXM: "",
        JBRQ: new Date(),
        JBR: "",
      },
    };
  },
  methods: {
    async fetch(Id) {
      try {
        const response = await this.$ajax.get(
          this.$appConfig.apiUrls.ywxtApi + "/api/YW/Getjsydghxkyj?BJID=" + Id
        );

        if (response.data.IsSuccess) {
          this.form = response.data.Data;
        } else {
          throw response.data.ErrorMessage;
        }

        const user = await mgr.getUser();
        if (this.form.JBR === user.profile.sub && this.$route.query.taskId) {
          this.readOnly = false;
        }
      } catch (error) {
        console.log(error);
      }
    },
    async onSubmit() {
      try {
        const response = await this.$ajax.post(
          this.$appConfig.apiUrls.ywxtApi + "/api/YW/AddOrUpdatejsydghxkyj",
          this.form
        );
        if (response.data.IsSuccess) {
          ElMessage({
            type: "success",
            message: "保存成功!",
          });
          this.form = response.data.Data;
        } else {
          ElMessage({
            type: "info",
            message: "保存失败!",
          });
          throw response.data.ErrorMessage;
        }
      } catch (error) {
        console.log(error);
      }
    },
  },
  mounted() {
    this.form.BJID = this.$route.query.instanceId ?? "";
    this.fetch(this.form.BJID);
  },
};
</script>

<style scoped>
.edit-header {
  display: flex;
  justify-content: space-between;
  align-items: center;
}
.el-form {
  width: 100%;
  overflow: hidden;
}
.input-width {
  width: 100%;
}
</style>
